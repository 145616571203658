@tailwind base;
@tailwind components;
@tailwind utilities;
@import './css/vars';
@import './css/reset.scss';
@import './css/global';
@import './css/tmp-scss/pages';
@import './css/tmp-scss/aside';
@import './css/tmp-scss/home';
@import './css/tmp-scss/login';
@import './css/tmp-scss/input';
@import './css/tmp-scss/counter';
@import './css/tmp-scss/report';
@import './css/tmp-scss/profile';
@import './css/tmp-scss/header';


