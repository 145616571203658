.report {
	&__title {
		margin-bottom: 50px;

		@include adpt($bmob-r) {
			margin-bottom: 30px;
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;

		@include adpt($tab-r) {
			flex-direction: column-reverse;
			align-items: flex-start;
			max-width: 320px;
			width: 100%;
		}

		@include adpt($bmob-r) {
			max-width: 100%;
		}
	}

	&__top-right {
		@include adpt($tab-r) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;

			& button {
				width: 100%;
			}
		}
	}

	&__top-left {
		margin-right: 30px;
		display: flex;
		align-items: center;

		@include adpt($tab-r) {
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
		}

		& button {
			@include adpt($tab-r) {
				width: 100%;
			}
		}

		& .ant-select {
			@include adpt($tab-r) {
				width: 100% !important;
			}
		}

		& > * {
			&:not(:last-child) {
				margin-right: 15px;

				@include adpt($tab-r) {
					margin-right: 0;
					margin-bottom: 20px;
					width: 100%;
				}
			}
		}
	}

	&__btn-exel {
		background-color: #0c990c;

		&:hover,
		&:active {
			background-color: #57a557 !important;
		}
	}

	&__graphic {
		margin-bottom: 70px;
		max-width: 100%;
		padding-top: 20px;
		padding-right: 30px;

		//overflow-x: auto;
		& svg {
			overflow: visible;
		}
	}

	&__graphic-top-left {
		position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 100px;
	}
}
