html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}


body {
  font-family: $mainFont;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  background-color: $white;
  color: $default;
  overflow-x: hidden;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 15px;
}

.main {
  flex: 1;
}

.sr-only {
  position: absolute;
  width: 0px;
  height: 0px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.burger {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

._fixed {
  overflow: hidden;
}

.section {
  padding: 70px 0;
}

.btn {
  padding: 15px 50px;
  border-radius: 5px;
  cursor: pointer;
  background-color: $accent;
  color: $white;
  font-weight: 500;
  text-align: center;
  font-size: 15px;
  line-height: 1;
  border: 1px solid $accent;
  transition: all 0.3s;

  &:hover {
    background-color: $white;
    color: $accent;
  }

  &_2 {
    background-color: $blue2;
    color: $black;
    border-color: $blue2;

    &:hover {
      background-color: $accent;
      color: $white;
      border-color: $accent;
    }
  }
}

._h3 {
  font-family: $secondFont;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;

  @include adpt ($tab-r) {
    font-size: 28px;
  }
}

._h4 {
  font-size: 26px;

  @include adpt ($tab-r) {
    font-size: 22px;
  }
}

.title {
  font-weight: 700;
  font-size: 44px;
  line-height: 1.1;
  color: $black;

  @include adpt ($desm-r) {
    font-size: 40px;
  }

  @include adpt ($tab-r) {
    font-size: 36px;
  }

  @include adpt ($stab-r) {
    font-size: 34px;
  }

  @include adpt ($bmob-r) {
    font-size: 30px;
  }
}

.ant-picker-panels {
  @include adpt ($stab-r) {
    flex-direction: column;
  }
}

.ant-picker-panel-layout {
  @include adpt ($bmob-r) {
    flex-direction: column;
  }

  & .ant-picker-presets {
    @include adpt ($bmob-r) {
      max-width: 100% !important;
    }
  }

  & .ant-picker-presets ul {
    @include adpt ($bmob-r) {
      height: auto !important;
    }
  }
}