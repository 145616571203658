.aside {
  height: 100%;
  background-color: $accent;
  color: $white;
  padding-top: 40px;

  @include adpt ($stab-r) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    padding-top: 60px;
    pointer-events: none;
    transition: all 0.3s;
    opacity: 0;
    transform: translateX(100%);

    &._open {
      opacity: 1;
      transform: translate(0);
      pointer-events: all;
    }
  }

  &__menu-btn {
    display: none !important;

    @include adpt ($stab-r) {
      display: flex !important;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  &__link {
    padding: 10px 15px;
    display: block;
    font-weight: 500;
    color: $white;
    width: 100%;
    text-align: left;
    transition: background-color 0.3s, color 0.3s;

    &.active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  &__item {
    &:last-child {
      margin-top: 90px;

      & .aside__link {
        background-color: rgba(255, 255, 255, 0.7);
        color: rgb(255, 0, 0);

        &:hover {
          color: $white;
          background-color: rgb(255, 0, 0);
        }
      }
    }
  }
}