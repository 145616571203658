.profile {
  &__table {
    overflow-x: auto;

    @include adpt ($tab-r) {
      width: max-content;
    }

    & th {
      @include adpt ($tab-r) {
        white-space: nowrap;

      }
    }
    & table{
      @include adpt ($tab-r){
        table-layout: auto !important;
      }
    }
  }

}