.home {
  &__title {
    margin-bottom: 50px;
    @include adpt ($bmob-r) {
      margin-bottom: 30px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__logo {
    margin-bottom: 70px;
    @include adpt ($bmob-r)  {
      margin-bottom: 50px;
    }
  }

  &__list {
    max-width: 500px;
    width: 100%;
  }

  &__item {
    background-color: $bg;
    border-radius: 20px;

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  &__item-title {
    font-family: $secondFont;
    font-weight: 600;
    padding: 20px;
    padding-bottom: 20px;
    display: block;
    text-align: center;
    font-size: 22px;
    border-bottom: 1px solid $grey;
    @include adpt ($bmob-r) {
      font-size: 20px;
    }
  }

  &__item-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__item-box {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      padding-right: 14px;
      border-right: 1px solid $grey;
    }

    &:last-child {
      padding-left: 14px;
      flex-direction: row-reverse;
    }

    & span {
      font-family: $secondFont;
    }

    & span:last-child {
      font-size: 20px;
      font-weight: 700;
    }
  }
}