.counter {
	&__title {
		margin-bottom: 50px;
		@include adpt ($bmob-r) {
			margin-bottom: 30px;
		}
	}

	&__add {
		position: relative;
		left: 100%;
		transform: translateX(-100%);
		margin-bottom: 50px;
		@include adpt ($bmob-r) {
			margin-bottom: 30px;
		}
	}

	&__btn {
		display: flex;
		gap: 0.5rem;
	}
}
