.loader {
	width: 80px;
	height: 80px;
	margin: auto;

	> div {
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 6px solid black;
		border-radius: 100%;
		box-sizing: border-box;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: var(--accent-color) transparent transparent transparent;
	}

	div:nth-child(1) {
		animation-delay: -0.45s;
	}
	div:nth-child(2) {
		animation-delay: -0.3s;
	}
	div:nth-child(3) {
		animation-delay: -0.15s;
	}
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
