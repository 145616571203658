.login {
	background-color: $bg;
	min-height: 100%;
	&__wrap {
		padding: 20px;
		border-radius: 20px;
		transform: translate(-50%, -50%);
		left: 50%;
		position: absolute;
		top: 50%;
		width: -webkit-fill-available;
		max-width: 500px;
		min-width: 270px;
		background-color: $white;
		border: 1px solid $grey;
	}

	&__title {
		text-align: center;
		margin-bottom: 30px;
	}
	&__inner {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 10px;
	}
	&__btn {
		margin-top: 20px;
	}
	&__bottom {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid $grey;
	}
	&__text {
		font-size: 14px;
		& a {
			color: $accent;
			text-decoration: underline;
		}
	}
}
