.input {
	&__label {
		font-size: 12px;
		display: block;
		opacity: 0.8;
		margin-bottom: 4px;

		&::before {
			content: '*';
			color: red;
		}
	}

	&__input {
		max-width: 100%;
		width: 100%;
		border-radius: 5px;
		border: 1px solid $grey;
		padding: 5px;
		font-family: $secondFont;
		line-height: 1.4;
		font-size: 16px;
		outline: none;
		transition:
			border-color 0.3s,
			box-shadow 0.3s;

		&:focus {
			border-color: $accent;
			box-shadow: 0 0 5px rgba(#3690e4, 0.3);
		}
	}

	&__error {
		margin-top: 3px;
		font-size: 12px;
		color: red;
	}
}
