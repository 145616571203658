.pages {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 20px;
  min-height: 100%;

  @include adpt ($stab-r) {
    grid-template-columns: 1fr;
    padding: 0 15px;
  }

  &__inner {
    padding: 40px 0;
    padding-right: 20px;
    min-width: 0;

    @include adpt ($bmob-r) {
      padding-right: 0;
    }
  }
}